<template>
    <div>
        <zw-input-group :value="$t('article.label.regenerate')"
                        name="new_value"
                        :label-prefix="labelPrefix"
                        readonly
        ></zw-input-group>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    props: {
        'value': {},
        'labelPrefix': {},
    },
    methods: {
    },
    mounted() {
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    }
}
</script>